import { render, staticRenderFns } from "./index.vue?vue&type=template&id=52d7a582&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=52d7a582&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52d7a582",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CBox: require('@chakra-ui/vue').CBox, CButton: require('@chakra-ui/vue').CButton, CMenuDivider: require('@chakra-ui/vue').CMenuDivider, CFlex: require('@chakra-ui/vue').CFlex, CSelect: require('@chakra-ui/vue').CSelect, CTab: require('@chakra-ui/vue').CTab, CTabList: require('@chakra-ui/vue').CTabList, CTabs: require('@chakra-ui/vue').CTabs})
